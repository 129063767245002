import React from 'react'
import Reviews from '../components/ReviewComponents/Reviews'

const ReviewContainer = () => {
  return (
    <div>
        <Reviews/>
    </div>
  )
}

export default ReviewContainer