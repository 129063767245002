import React from 'react';
import QuoteLink from '../components/QuoteComponents/QuoteLink';


const QuoteContainer = () => {
  
  return (
    <div>
      <QuoteLink />
    </div>
  );
}

export default QuoteContainer;
